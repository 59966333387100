import axios from 'axios';
/**
 * 연혁 데이터를 가져온다.
 */
export const getHistories = async () => {
  // const response = await axios.get('/api/histories');
  // Use relative path to load local JSON file
  const response = { data: require('./data/history_data.json') };

  return response.data;
};

/**
 * 연혁 데이터를 추가한다.
 */
export const addHistory = async (history) => {
  const response = await axios.post('/api/histories', history);
  return response.data;
};

/**
 * 연혁 데이터를 삭제한다.
 */
export const deleteHistory = async (id) => {
  const response = await axios.delete(`/api/histories/${id}`);
  return response.data;
};

/**
 * 목회자 리스트를 가져온다.
 */
export const getPastors = async () => {
  // const response = await axios.get('/api/pastors');
  const response = { data: require('./data/pastors_data.json') };
  return response.data;
};

/**
 * 목회자를 추가한다.
 */
export const addPastor = async (pastor) => {
  const response = await axios.post('/api/pastors', pastor);
  return response.data;
};

/**
 * 목회자 정보를 수정한다.
 */
export const updatePastor = async (id, pastor) => {
  const response = await axios.put(`/api/pastors/${id}`, pastor);
  return response.data;
};

/**
 * 목회자를 삭제한다.
 */
export const deletePastor = async (id) => {
  const response = await axios.delete(`/api/pastors/${id}`);
  return response.data;
};

/**
 * 통계 데이터를 가져온다.
 */
export const getStatistics = async () => {
  // const response = await axios.get('/api/statistics');
  const response = { data: require('./data/statistics_data.json') };
  return response.data;
};

/**
 * 통계 데이터를 추가한다.
 */
export const addStatistic = async (statistic) => {
  const response = await axios.post('/api/statistics', statistic);
  return response.data;
};

/**
 * 통계 데이터를 수정한다.
 */
export const updateStatistic = async (id, statistic) => {
  const response = await axios.put(`/api/statistics/${id}`, statistic);
  return response.data;
};

/**
 * 통계 데이터를 삭제한다.
 */
export const deleteStatistic = async (id) => {
  const response = await axios.delete(`/api/statistics/${id}`);
  return response.data;
};

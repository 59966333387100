import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { CommonContext } from '../../contexts';

const InfoPresident = () => {
  const { categories } = useContext(CommonContext);
  const { pathname } = useLocation();

  return (
    // <!--s:#primary -->
    <div id="primary" className="content-area">
      {/* <!--s:#content --> */}
      <div id="content" className="site-content">
        <article>
          <div>
            <div className="title-inner">
              <img src="/common/images/title.png" alt="" />
              <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
            </div>
            <div style={{ height: 20 }}></div>
            <div style={{ clear: 'both' }}></div>
            <div className="title_subpage_solid_1"></div>
            <div style={{ height: 30 }}></div>
            <div className="greetings_wrap">
              <div className="x-column x-sm vc x-1-2"></div>
              <div className="single_image mb20"></div>
              <div>
                <p>
                  <img src="/common/images/insa_lbj.jpeg" className="president" alt="" />
                  눈밭에서 피어난 붉은 동백꽃처럼 점점 어려워져 가는 환경에서도 열정의 꽃을 피우고 싶습니다.
                  <br />
                  <br />
                  나무에서 한 번 땅 위에서 또 한 번 그리고 바람에 불려가 물 위에서 다시 꽃을 피우고 싶습니다.
                  <br />
                  <br />
                  그런 열정들이 모여서 도란도란 소곤소곤 이곳 영남합회 홈페이지에서 나눠진다며 우린 작은 불꽃이 되고 나중에 큰불이 되어서 온 세상을 태울 부흥의 불길로 일어날 것입니다.
                  <br />
                  <br />
                  이곳 영남합회 홈페이지가 단순한 행정 사무뿐 아니라 선한 감화력이 나누어지는 소통의 공간이 되었으면 좋겠습니다.
                  <br />
                  <br />
                  <span style={{ float: 'right' }}>
                    영남합회장 <span style={{ marginLeft: 10, fontWeight: 'bolder' }}>이병주</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          {/* <!-- .entry-content --> */}
        </article>
      </div>
      {/* <!--//e: #content --> */}
    </div>
  );
};

export default InfoPresident;

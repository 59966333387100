import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ProgressContext } from '../../contexts';
import styles from './ChurchPastors.module.css';
import { addPastor, deletePastor, getPastors } from './ChurchService';

const ChurchPastors = () => {
  const { spinner } = useContext(ProgressContext);
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [period, setPeriod] = useState('');
  const [pastors, setPastors] = useState([]);

  const fetchPastors = async () => {
    try {
      spinner.start();
      const data = await getPastors();
      setPastors(data);
    } catch (error) {
      toast.error('교역자 데이터를 불러오는데 실패했습니다.');
    } finally {
      spinner.stop();
    }
  };

  useEffect(() => {
    fetchPastors();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      spinner.start();
      await addPastor({ name, role, period });
      await fetchPastors();
      setName('');
      setRole('');
      setPeriod('');
      toast.success('교역자가 추가되었습니다.');
    } catch (error) {
      toast.error('교역자 추가에 실패했습니다.');
    } finally {
      spinner.stop();
    }
  };

  const handleDelete = async (id) => {
    try {
      spinner.start();
      await deletePastor(id);
      await fetchPastors();
      toast.success('교역자가 삭제되었습니다.');
    } catch (error) {
      toast.error('교역자 삭제에 실패했습니다.');
    } finally {
      spinner.stop();
    }
  };

  const handleEdit = async (id) => {
    // 수정 로직 구현
  };

  const handleChange = (value, id, field) => {
    setPastors((prevPastors) => prevPastors.map((pastor) => (pastor.id === id ? { ...pastor, [field]: value } : pastor)));
  };

  const handlePeriodChange = (value, id) => {
    // 입력 시에는 YYYY-MM-DD 형식을 허용하고, 표시할 때만 변환
    handleChange(value, id, 'period');
  };

  return (
    <div className="pastors-content">
      <form onSubmit={handleSubmit}>
        <div className={styles.pastorsForm}>
          <input
            type="text" //
            width={100}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="성명을 입력하세요"
            required
            style={{ width: 120 }}
          />
          <select value={role} onChange={(e) => setRole(e.target.value)} required>
            <option value="">직책 선택</option>
            <option value="전도사">전도사</option>
            <option value="인준목사">인준목사</option>
            <option value="목사">목사</option>
            <option value="예배소장">예배소장</option>
          </select>
          <input
            type="text"
            value={period}
            width={100}
            onChange={(e) => setPeriod(e.target.value)} //
            placeholder="재임기간 (예: 2020.03.01 ~ 2023.02.28)"
            required
            style={{ flex: 1 }}
          />
          <button type="submit" className={`${styles.button} ${name && role && period ? styles.active : styles.inActive}`} disabled={!name || !role || !period}>
            등록
          </button>
        </div>
      </form>

      <div className={styles.pastorsList}>
        <table>
          <thead>
            <tr>
              <th width="15%">성명</th>
              <th width="15%">직책</th>
              <th width="*">재임기간</th>
              <th width="20%"></th>
            </tr>
          </thead>
          <tbody>
            {pastors.map((pastor) => (
              <tr key={pastor.id}>
                <td>
                  <input type="text" value={pastor.name} onChange={(e) => handleChange(e.target.value, pastor.id, 'name')} className={styles.editInput} />
                </td>
                <td>
                  <select value={pastor.role} onChange={(e) => handleChange(e.target.value, pastor.id, 'role')} className={styles.editInput}>
                    <option value="전도사">전도사</option>
                    <option value="인준목사">인준목사</option>
                    <option value="목사">목사</option>
                    <option value="예배소장">예배소장</option>
                  </select>
                </td>
                <td>
                  <input type="text" value={pastor.period} onChange={(e) => handlePeriodChange(e.target.value, pastor.id)} className={styles.editInput} placeholder="YYYY-MM-DD~YYYY-MM-DD" />
                </td>
                <td>
                  <button
                    onClick={() => handleEdit(pastor.id)} //
                    className={`${styles.button} ${styles.editButton}`}
                  >
                    수정
                  </button>
                  <button
                    onClick={() => handleDelete(pastor.id)} //
                    className={`${styles.button} ${styles.deleteButton}`}
                  >
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChurchPastors;

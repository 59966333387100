/** @format */

import React, { useContext } from 'react';
import styled from 'styled-components';

import { Route, Routes } from 'react-router';
import { Spinner } from './components';
import { CommonContext, ProgressContext } from './contexts';

import AdminTemplate from './screens/AdminTemplate';
import BaseTemplate from './screens/BaseTemplate';
import MainTemplate from './screens/MainTemplate';

// Information
import InfoCommittees from './screens/info/InfoCommittees';
import InfoConference from './screens/info/InfoConference';
import InfoLocations from './screens/info/InfoLocations';
import InfoManagers from './screens/info/InfoManagers';
import InfoPastors from './screens/info/InfoPastors';
import InfoPresident from './screens/info/InfoPresident';
import InfoSchedule from './screens/info/InfoSchedule';
import InfoYCLocation from './screens/info/InfoYCLocation';

import ScreenBlock from './components/ScreenBlock';

// Board
import BoardEdit from './screens/board/BoardEdit';
import BoardView from './screens/board/BoardView';
import BoardWrite from './screens/board/BoardWrite';

// Member
import EditForm from './screens/member/EditForm';
import Login from './screens/member/Login';
import ResetPassword from './screens/member/ResetPassword';
import Signup from './screens/member/Signup';

// Authorization
import BaseAuth from './authentication/BaseAuth';
import CategoryAuth from './authentication/CategoryAuth';

// Admin
import AdminActivityReports from './screens/admin/AdminActivityReports';
import AdminCalendars from './screens/admin/AdminCalendars';
import AdminList from './screens/admin/AdminList';
import AdminRollingLink from './screens/admin/AdminRollingLinks';

// etc
import Denial from './screens/etc/Denial';
import License from './screens/etc/License';
import Privacy from './screens/etc/Privacy';
import Warning from './screens/etc/Warning';

// report
import MonthlyReport from './screens/report/MonthlyReport';
import MonthlyReportPrint from './screens/report/MonthlyReportPrint';

// termReport
import TermReport from './screens/termReport/TermReport';

// test
import TermReportPrint from './screens/termReport/TermReportPrint';
import Test from './screens/test/Eddy';

// admin termReport
import AdminActivityTermReports from './screens/admin/adminTermReport/AdminActivityTermReports';

import AdminChurchEditor from './screens/admin/adminChurchEditor/AdminChurchEditor';
import AdminChurchesManager from './screens/admin/adminChurchesManager/AdminChurchesManager';
import AdminCommittees from './screens/admin/AdminCommittees';
import AdminEmployees from './screens/admin/AdminEmployees';
import AdminConfirmTermReport from './screens/admin/adminTermReport/adminComfirm/AdminConfirmTermReport';
import AdminManageTermReport from './screens/admin/adminTermReport/AdminManageTermReport';
import AdminTermReportPrint from './screens/admin/adminTermReport/AdminTermReportPrint';
import SummingUpTermReport from './screens/admin/adminTermReport/SummingUpTermReport';
import BoardPages from './screens/board/BoardPages';
import ChurchMain from './screens/church/ChurchMain';
import Attendance from './screens/test/Attendance';

const Container = styled.div``;

const Router = () => {
  const { inProgress } = useContext(ProgressContext);
  const { sideMenuVisible } = useContext(CommonContext); // Side Menu가 활성화 되어 있는가?

  return (
    <Container>
      {inProgress && <Spinner />}
      {sideMenuVisible && <ScreenBlock />}

      <Routes>
        {/* [첫 화면] Main Router ------- */}
        <Route path="/" element={<MainTemplate />} />

        {/* [정보 page] ---------------------------------------- */}
        <Route path="page" element={<BaseTemplate />}>
          <Route path=":groupCode/:categoryId/" element={<CategoryAuth />}>
            <Route path="intro" element={<InfoConference />} />
            <Route path="greeting" element={<InfoPresident />} />
            <Route path="schedule" element={<InfoSchedule />} />
            <Route path="pastors" element={<InfoPastors />} />
            <Route path="locations" element={<InfoLocations />} />
            <Route path="managers" element={<InfoManagers />} />
            <Route path="admins" element={<InfoCommittees />} />
            <Route path="path" element={<InfoYCLocation />} />

            {/* Report*/}
            <Route path="monthlyReport" element={<MonthlyReport />} />

            {/* [기말보고] ---------------------------------------- */}

            <Route path="termReport" element={<TermReport />} />
          </Route>
        </Route>

        {/* [회원 member] ---------------------------------------- */}
        <Route path="member" element={<BaseTemplate />}>
          {/* 공개 & 제한 URL ------- */}
          <Route path=":groupCode/:categoryId/" element={<BaseAuth isPublic={true} restricted={true} />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="resetPassword" element={<ResetPassword />} />
          </Route>
          {/* 비공개 URL ------- */}
          <Route path=":groupCode/:categoryId/" element={<BaseAuth isPublic={false} />}>
            <Route path="edit" element={<EditForm />} />
            <Route path="resetPassw0rd" element={<ResetPassword />} />
            <Route path="history" element={<ChurchMain />} />
          </Route>
        </Route>

        {/* [게시판 Board] ---------------------------------------- */}
        <Route path="board" element={<BaseTemplate />}>
          <Route path=":groupCode/:categoryId/" element={<CategoryAuth />}>
            <Route path="view" element={<BoardView />} />
            <Route path="list" element={<BoardPages />} />
            <Route path="write" element={<BoardWrite />} />
            <Route path="edit" element={<BoardEdit />} />
            <Route path="*" element={<BoardPages />} />
          </Route>
        </Route>

        {/* [관리 admin] ---------------------------------------- */}
        <Route path="admin" element={<AdminTemplate />}>
          <Route path=":groupCode/:categoryId/" element={<CategoryAuth />}>
            <Route path="memberList" element={<AdminList />} />
            <Route path="rollingLInks" element={<AdminRollingLink />} />
            <Route path="subscribeCalendars" element={<AdminCalendars />} />
            <Route path="employees" element={<AdminEmployees />} />
            <Route path="committees" element={<AdminCommittees />} />
            <Route path="activityReports" element={<AdminActivityReports />} />
            <Route path="activityTermReports" element={<AdminActivityTermReports />} /> {/* 기말보고 현황 */}
            <Route path="churchManager" element={<AdminChurchesManager />} /> {/* 교회-목회자 관리 */}
            <Route path="churchEditor" element={<AdminChurchEditor />} /> {/* 교회 생성 및 수정 */}
          </Route>
        </Route>

        <Route path="adminManageTermReport" element={<AdminManageTermReport />} />
        <Route path="adminConfirmTermReport" element={<AdminConfirmTermReport />} />
        <Route path="summingUpTermReport" element={<SummingUpTermReport />} />
        <Route path="printReport/:reportId/:exportMethod" element={<MonthlyReportPrint />} />
        <Route path="printTermReport/:reportId/:exportMethod" element={<TermReportPrint />} />
        <Route path="printTermReport/:exportMethod/:stage/:dt" element={<AdminTermReportPrint />} />
        <Route path="termReportByAdmin/:exportMethod/:churchId/:dt" element={<AdminTermReportPrint />} />

        {/* ETC ---------------------------------------- */}
        <Route path="privacy" element={<Privacy />} />
        <Route path="denial" element={<Denial />} />
        <Route path="license" element={<License />} />
        <Route path="*" element={<Warning />} />

        {/* Test를 위해서 만들어서 쓰는 페이지 ---------------------------------------- */}
        <Route path="test" element={<Test />} />
        <Route path="attendance" element={<Attendance />} />
      </Routes>
    </Container>
  );
};

export default Router;

import axios from 'axios';

/**
 * 교회 정보를 가지고 온다.
 * @param {number} memberId
 * @returns
 */
export const getChurchInfo = async (memberId) => {
  let result = null;

  try {
    const res = await axios.get(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/churchHistory/churches/members/${memberId}/church`);

    result = res.data[0] || {};
  } catch (error) {
    console.log(error);
  }

  return result;
};

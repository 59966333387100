import React from 'react';
import DatePicker from 'react-datepicker';
import './customReactDatepicker.css';
import { ko } from 'date-fns/locale';
import styles from './DateInput.module.css';

/**
 * 날짜를 입력받는 컴포넌트
 * @param {string} value - 날짜 값
 * @param {function} onChange - 날짜 값 변경 함수
 * @param {object} containerStyle - 컨테이너 스타일
 */

const DateInput = ({ value, onChange, containerStyle }) => {
  const handleChange = (date) => {
    if (!date) return;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    onChange(`${year}-${month}-${day}`);
  };

  const parseDate = (dateStr) => {
    if (!dateStr) return null;
    const [year, month, day] = dateStr.split('-');
    return new Date(year, parseInt(month) - 1, day);
  };

  return (
    <div className={`${styles.dateInputWrapper}`} style={containerStyle}>
      <DatePicker
        selected={parseDate(value)} //
        onChange={handleChange}
        dateFormat="yyyy.MM.dd."
        locale={ko}
        className={styles.dateInput}
        placeholderText="날짜를 입력하세요"
      />
    </div>
  );
};

export default DateInput;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CommonContext, UserContext } from '../../contexts';
import { DataTypes } from '../../data/Types';

const MainNavBar = () => {
  const { categoryGroup, categories, groupCategoryRel, dispatchSideMenuVisible } = useContext(CommonContext);
  const { user } = useContext(UserContext);

  /** 모바일 사이드 메뉴 바를 보이도록 설정한다.  */
  const _handleSetSideMenuVisible = () => {
    dispatchSideMenuVisible(true);
  };

  return (
    <header className="banner">
      <div id="main-nav" className="dark-div nav-style-1">
        <nav className="navbar navbar-inverse main-color-2-bg" role="navigation">
          {/* <!--s:container--> */}
          <div className="container">
            <div className="navbar-header">
              <Link className="logo" to="/">
                <img className="wpml-ls-flag" src="/common/images/logo.jpg" alt="logo" />
              </Link>
            </div>
            {/* <!--s:main menu--> */}
            <div className="main-menu">
              <div className="clearfix">
                <div className="row">
                  <ul className="nav navbar-nav navbar-right">
                    {categoryGroup
                      .filter((group) => group.public === 'Y')
                      .map((group, index) => {
                        // 정보 | 합회보고 | 자료실 | 게시판
                        // const firstCategory = categories.find((category) => category.id === groupCategoryRel.find((rel) => rel.groupId === group.id)?.categoryId);

                        return (
                          <li key={index} className="dropdown">
                            <Link
                              // to={`/${firstCategory?.categoryType}/${group.groupCode}/${firstCategory?.id}/${firstCategory?.categoryType === 'board' ? 'list?offset=0' : firstCategory?.categoryCode}`}
                              to="#"
                            >
                              <span>{group.groupName}</span>
                            </Link>
                            <ul className="dropdown-menu">
                              {groupCategoryRel
                                .filter((rel) => rel.groupId === group.id) // 각 그룹 안의 카테고리 항목
                                .map((result) => {
                                  const currentCategory = categories.find((category) => category.id === result.categoryId);

                                  return (
                                    <React.Fragment key={result.categoryId}>
                                      {(user.level || 0) >= currentCategory.readLevel && (
                                        <li key={result.categoryId}>
                                          <Link
                                            to={`/${currentCategory.categoryType}/${group.groupCode}/${result.categoryId}/${
                                              currentCategory.categoryType === 'board' ? 'list?offset=0' : currentCategory.categoryCode
                                            }`}
                                          >
                                            {currentCategory?.categoryName}
                                            {user.level >= DataTypes.USER_LEVEL_MANAGER && <>({currentCategory?.sortNum})</>}
                                          </Link>
                                        </li>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                            </ul>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            {/* <!--//e:main menu--> */}
          </div>
          {/* <!--//e:container--> */}
        </nav>
      </div>
      {/* <!--//e:#main-nav--> */}
      <div id="main-navbar">
        <div className="toggle-sidebar" onClick={_handleSetSideMenuVisible}>
          <span>
            <span className="slicknav_icon-bar"></span>
            <span className="slicknav_icon-bar"></span>
            <span className="slicknav_icon-bar"></span>
          </span>
        </div>
      </div>
    </header>
  );
};

export default MainNavBar;

import React, { useContext } from 'react';
import { CommonContext } from '../../contexts';
import { useLocation } from 'react-router-dom';

const InfoConference = () => {
  const { categories } = useContext(CommonContext);
  const { pathname } = useLocation();

  return (
    <div id="primary" className="content-area">
      {/* <!--s:#content --> */}
      <div id="content" className="site-content">
        <article>
          <div>
            <div className="title-inner">
              <img src="/common/images/title.png" alt="" />
              <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
            </div>
            <div style={{ height: 20 }}></div>
            <div style={{ clear: 'both' }}></div>
            <div className="title_subpage_solid_1"></div>
            <div style={{ height: 30 }}></div>

            <div className="greetings_wrap">
              <div className="Box1">
                <ul>
                  <li>
                    <img src="/common/images/ADVENTIST_LOGO.png" alt="CI" />
                  </li>
                </ul>
              </div>
              <div className="Box2">
                <div className="Box3">
                  <p className="txt1">
                    영남합회는 대구, 부산, 울산 등 3개의 광역시를 포함한 경남, 경북 지역의 선교를 책임진 합회로서 <b className="green">123개의 조직된 교회</b>와{' '}
                    <b className="green">27개의 미조직된 예배소</b>로 구성되어 있습니다.
                    <br />
                    <br />
                    2021년 현재 93명의 안수목사를 비롯한 <b className="green">105명의 목회자</b>가 영남지역의 각 교회를 섬기고 있으며 <b className="green">20,087명의 재적교인수, 5,944명의 안교생수</b>
                    가 등록되어 있습니다.
                    <br />
                    <br />
                    합회 내 관련 기관으로서는 <b className="green">영남삼육중고등학교, 대구삼육초등학교, 부산삼육초등학교</b> 등 세곳의 교육기관이 자라나는 어린이, 청소년들을 돌보고 있으며, 지역사회를
                    위한 봉사와 복음재정, 교육재정 등의 마련을 위해 <b className="green">삼육부산병원, 네이처세븐</b> 등의 기관들이 운영되고 있습니다.
                  </p>
                </div>
              </div>
              <div className="clr"></div>
            </div>
            <div className="greetings_wrap greetings_wrap1">
              <div className="Box1">
                <ul>
                  <li>
                    <img src="/common/images/yn_map.jpg" alt="yn_map" />
                  </li>
                </ul>
              </div>
              <div className="Box2">
                <ul>
                  <li>
                    <span>본부</span> 대구시 수성구 월드컵로 125(시지동 568) (우) 42250
                  </li>
                  <li>
                    <span>국제사서함</span> Southeast Korean Conference of SDA Daegu P.O.Box 42 Daegu 41918, Korea
                  </li>
                  <li>
                    <span>팩시밀리</span> 합회 053-654-1760 / 서회 054-654-1769
                  </li>
                  <li>
                    <span>거래은행</span>
                    <div className="table_wrap">
                      <table className="tbl_basic">
                        <colgroup>
                          <col style={{ width: 170 }} />
                          <col style={{ width: 100 }} />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th rowSpan="3">영남합회</th>
                            <td>농협</td>
                            <td>703-01-014017</td>
                          </tr>
                          <tr>
                            <td>우체국</td>
                            <td>700039-01-014640</td>
                          </tr>
                          <tr>
                            <td>하나은행</td>
                            <td>104-890000-83305</td>
                          </tr>
                          <tr>
                            <th>
                              영남합회
                              <br />
                              (선교부)
                            </th>
                            <td>농협</td>
                            <td>301-0058-3253-61</td>
                          </tr>
                          <tr>
                            <th>
                              영남합회
                              <br />
                              (삼육지역사회봉사회)
                            </th>
                            <td>농협</td>
                            <td>301-0332-0498-41</td>
                          </tr>
                          <tr>
                            <th>
                              영남합회
                              <br />
                              (영남ADRA)
                            </th>
                            <td>
                              IM뱅크
                              <br />
                              (구.대구은행)
                            </td>
                            <td>505-10-231586-9</td>
                          </tr>
                          <tr>
                            <th>
                              영남합회
                              <br />
                              (어린이부)
                            </th>
                            <td>우체국</td>
                            <td>700039-01-014667</td>
                          </tr>
                          <tr>
                            <th>
                              영남합회
                              <br />
                              (문전인)
                            </th>
                            <td>우체국</td>
                            <td>700039-01-014653</td>
                          </tr>
                          <tr>
                            <th rowSpan="2">
                              영남합회
                              <br />
                              (서회)
                            </th>
                            <td>우체국</td>
                            <td>702449-01-000570</td>
                          </tr>
                          <tr>
                            <td>농협</td>
                            <td>704-01-208520</td>
                          </tr>
                          <tr>
                            <th>
                              영남합회
                              <br />
                              (ACT협회교회)
                            </th>
                            <td>국민</td>
                            <td>356237-04-003919</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="clr"></div>
            </div>
          </div>
          {/* <!-- .entry-content --> */}
        </article>
      </div>
      {/* <!--//e: #content --> */}
    </div>
  );
};

export default InfoConference;

/**
 * 이걸 DB에 넣어야 하나? 말아야 하나?
 * 2022.01.11.(수) @부산서부교회 전도사 사택
 *
 * @format
 */

export const MENU = [
  // [회원정보] ----------------------------------------------------------------------
  {
    groupCode: 'member',
    groupName: '로그인',
    categoryId: 'login',
    categoryName: '로그인',
  },
  {
    groupCode: 'member',
    groupName: '로그인',
    categoryId: 'signup',
    categoryName: '회원가입',
  },
  {
    groupCode: 'member',
    groupName: '로그인',
    categoryId: 'resetPassword',
    categoryName: '비밀번호 재설정',
  },
  {
    groupCode: 'member',
    groupName: '정보관리',
    categoryId: 'edit',
    categoryName: '정보관리',
  },
  {
    groupCode: 'member',
    groupName: '정보관리',
    categoryId: 'resetPassw0rd',
    categoryName: '비밀번호 재설정',
  },
];

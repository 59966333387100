import React, { useState, useEffect, useContext } from 'react';
import { ProgressContext } from '../../contexts';
import { getStatistics, addStatistic, updateStatistic, deleteStatistic } from './ChurchService';
import toast from 'react-hot-toast';
import styles from './ChurchStatistics.module.css';

const ChurchStatistics = () => {
  const { spinner } = useContext(ProgressContext);
  const [year, setYear] = useState('');
  const [elders, setElders] = useState('');
  const [deacons, setDeacons] = useState('');
  const [deaconesses, setDeaconesses] = useState('');
  const [average, setAverage] = useState('');
  const [statistics, setStatistics] = useState([]);
  const [editingId, setEditingId] = useState(null);

  const fetchStatistics = async () => {
    try {
      spinner.start();
      const data = await getStatistics();

      setStatistics(data);
    } catch (error) {
      toast.error('통계 데이터를 불러오는데 실패했습니다.');
    } finally {
      spinner.stop();
    }
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      spinner.start();
      await addStatistic({
        year,
        elders: Number(elders),
        deacons: Number(deacons),
        deaconesses: Number(deaconesses),
        average: Number(average),
      });
      await fetchStatistics();
      resetForm();
      toast.success('통계가 추가되었습니다.');
    } catch (error) {
      toast.error('통계 추가에 실패했습니다.');
    } finally {
      spinner.stop();
    }
  };

  const handleEdit = async (id) => {
    const stat = statistics.find((s) => s.id === id);
    if (stat) {
      setEditingId(id);
      setYear(stat.year);
      setElders(stat.elders);
      setDeacons(stat.deacons);
      setDeaconesses(stat.deaconesses);
      setAverage(stat.average);
    }
  };

  const handleUpdate = async (id) => {
    try {
      spinner.start();
      await updateStatistic(id, {
        year,
        elders: Number(elders),
        deacons: Number(deacons),
        deaconesses: Number(deaconesses),
        average: Number(average),
      });
      await fetchStatistics();
      resetForm();
      setEditingId(null);
      toast.success('통계가 수정되었습니다.');
    } catch (error) {
      toast.error('통계 수정에 실패했습니다.');
    } finally {
      spinner.stop();
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      try {
        spinner.start();
        await deleteStatistic(id);
        await fetchStatistics();
        toast.success('통계가 삭제되었습니다.');
      } catch (error) {
        toast.error('통계 삭제에 실패했습니다.');
      } finally {
        spinner.stop();
      }
    }
  };

  const resetForm = () => {
    setYear('');
    setElders('');
    setDeacons('');
    setDeaconesses('');
    setAverage('');
  };

  return (
    <div className={styles.content}>
      <form onSubmit={handleSubmit} className={styles.statisticsForm}>
        <select className={styles.select} value={year} onChange={(e) => setYear(e.target.value)} required>
          <option value="">연도 선택</option>
          {Array.from({ length: 200 }, (_, i) => new Date().getFullYear() - i).map((year) => (
            <option key={year} value={year}>
              {year}년
            </option>
          ))}
        </select>
        <input type="number" className={styles.numberInput} value={elders} onChange={(e) => setElders(e.target.value)} placeholder="장로 수" required />
        <input type="number" className={styles.numberInput} value={deacons} onChange={(e) => setDeacons(e.target.value)} placeholder="남집사 수" required />
        <input type="number" className={styles.numberInput} value={deaconesses} onChange={(e) => setDeaconesses(e.target.value)} placeholder="여집사 수" required />
        <input type="number" className={styles.numberInput} value={average} onChange={(e) => setAverage(e.target.value)} placeholder="평균출석 수" required />
        {editingId ? (
          <button type="button" className={`${styles.button} ${styles.active}`} onClick={() => handleUpdate(editingId)}>
            수정완료
          </button>
        ) : (
          <button type="submit" className={`${styles.button} ${styles.active}`}>
            추가
          </button>
        )}
      </form>

      <div className={styles.statisticsList}>
        <table>
          <thead>
            <tr>
              <th>연도</th>
              <th>장로 수</th>
              <th>남집사 수</th>
              <th>여집사 수</th>
              <th>평균출석 수</th>
              <th width="200"></th>
            </tr>
          </thead>
          <tbody>
            {statistics.map((stat) => (
              <tr key={stat.id}>
                <td className={styles.yearCell}>{stat.year}년</td>
                <td>{stat.elders}</td>
                <td>{stat.deacons}</td>
                <td>{stat.deaconesses}</td>
                <td>{stat.average}</td>
                <td>
                  <button
                    className={`${styles.button} ${styles.editButton}`} //
                    onClick={() => handleEdit(stat.id)}
                  >
                    수정
                  </button>
                  <button
                    className={`${styles.button} ${styles.deleteButton}`} //
                    onClick={() => handleDelete(stat.id)}
                  >
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChurchStatistics;

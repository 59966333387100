import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ProgressContext } from '../../contexts';
import { addHistory, deleteHistory, getHistories } from './ChurchService';
import styles from './ChurchHistory.module.css';
import DateInput from './components/DateInput';

const ChurchHistory = () => {
  const { spinner } = useContext(ProgressContext);
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [histories, setHistories] = useState([]);

  const fetchHistories = async () => {
    try {
      spinner.start();
      const data = await getHistories();
      setHistories(data);
    } catch (error) {
      toast.error('연혁 데이터를 불러오는데 실패했습니다.');
      console.error(error);
    } finally {
      spinner.stop();
    }
  };

  useEffect(() => {
    fetchHistories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      spinner.start();
      await addHistory({ date, description });
      await fetchHistories();
      setDate('');
      setDescription('');
      toast.success('연혁이 추가되었습니다.');
    } catch (error) {
      toast.error('연혁 추가에 실패했습니다.');
      console.error(error);
    } finally {
      spinner.stop();
    }
  };

  const handleChange = (value, id, field) => {
    const updatedHistories = histories.map((history) => (history.id === id ? { ...history, [field]: value } : history));
    setHistories(updatedHistories);
  };

  const handleEdit = async (id) => {
    // 수정 로직 구현
  };

  const handleDelete = async (id) => {
    try {
      spinner.start();
      await deleteHistory(id);
      await fetchHistories();
      toast.success('연혁이 삭제되었습니다.');
    } catch (error) {
      toast.error('연혁 삭제에 실패했습니다.');
      console.error(error);
    } finally {
      spinner.stop();
    }
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return `${year}.${month}.${day}`;
  };

  return (
    <div className="history-content">
      {/* 입력부 --------------------------- */}
      <form onSubmit={handleSubmit} className="history-form">
        <div className={styles.historyForm}>
          <DateInput value={date} onChange={setDate} containerStyle={{ marginRight: 10 }} />
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)} //
            placeholder="연혁 내용을 입력하세요"
            required
          />
          <button
            type="submit" //
            className={`${styles.button} ${date && description ? styles.active : styles.inActive}`}
            disabled={!date || !description}
          >
            등록
          </button>
        </div>
      </form>
      {/* 출력부 ---------------------------  */}
      <div className={styles.historyList}>
        <table>
          <thead>
            <tr>
              <th width="120"></th>
              <th width="*"></th>
              <th width="22%"></th>
            </tr>
          </thead>
          <tbody>
            {histories.map((item, index) => {
              const [year] = item.date.split('-');
              const currentDecade = Math.floor(parseInt(year) / 10) * 10;
              const prevDate = index > 0 ? histories[index - 1].date : null;
              const prevYear = prevDate ? parseInt(prevDate.split('-')[0]) : null;
              const prevDecade = prevYear ? Math.floor(prevYear / 10) * 10 : null;
              const isNewDecade = currentDecade !== prevDecade;

              return (
                <React.Fragment key={item.id}>
                  {isNewDecade && (
                    <tr className={styles.decadeDivider}>
                      <td colSpan="3" className={styles.decadeTitle}>
                        {currentDecade}년대
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <DateInput
                        value={item.date} //
                        onChange={(value) => handleChange(value, item.id, 'date')}
                        containerStyle={{ width: 100 }}
                      />
                    </td>
                    <td>
                      <input type="text" value={item.description} onChange={(e) => handleChange(e.target.value, item.id, 'description')} className={styles.descriptionInput} />
                    </td>
                    <td>
                      <button
                        onClick={() => handleEdit(item.id)} //
                        className={`${styles.button} ${styles.editButton}`}
                      >
                        수정
                      </button>
                      <button
                        onClick={() => handleDelete(item.id)} //
                        className={`${styles.button} ${styles.deleteButton}`}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChurchHistory;

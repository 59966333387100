import React, { useContext, useEffect, useState } from 'react';
import TabMenu from './TabMenu';
import ChurchHistory from './ChurchHistory';
import ChurchPastors from './ChurchPastors';
import ChurchStatistics from './ChurchStatistics';
import { UserContext } from '../../contexts';
import { get } from 'jquery';
import { getChurchInfo } from './data/church_data';

const ChurchMain = () => {
  const [churchInfo, setChurchInfo] = useState({});
  const [activeTab, setActiveTab] = useState('history');
  const tabs = [
    { id: 'history', label: '교회연혁' },
    { id: 'pastors', label: '교역자' },
    { id: 'statistics', label: '주요통계' },
  ];
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user || !user.id) return;

    getChurchInfo(user.id).then((church) => {
      setChurchInfo(church);
    });
  }, [user]);

  return (
    <div id="primary" className="content-area">
      <div id="content" className="site-content">
        <article>
          <div className="mem_level">
            <ul>
              <li>&nbsp;</li>
              <li className="hover">교회 연혁</li>
            </ul>
          </div>
          <div id="joinAgree">
            <div className="sub_tit">
              <h2>{churchInfo?.name || '-'}</h2>
            </div>

            <TabMenu activeTab={activeTab} tabs={tabs} onTabChange={setActiveTab} />

            {activeTab === 'history' && <ChurchHistory />}
            {activeTab === 'pastors' && <ChurchPastors />}
            {activeTab === 'statistics' && <ChurchStatistics />}
          </div>
        </article>
      </div>
    </div>
  );
};

export default ChurchMain;

import React, { useContext } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CommonContext, ProgressContext, UserContext } from '../../contexts';
import { DataTypes } from '../../data/Types';
import { isLogin, logout } from '../../data/firebase';

const TopBar = () => {
  const { settings, categories } = useContext(CommonContext);
  const { spinner } = useContext(ProgressContext);
  const { dispatch } = useContext(UserContext);
  const { user } = useContext(UserContext);

  const groupCode = useParams().groupCode;
  const { pathname } = useLocation();

  /**
   * 로그아웃
   */
  const _handleLogout = async () => {
    try {
      spinner.start();
      await logout();
      toast.success('로그아웃 되었습니다');
    } catch (e) {
      console.log(e.message);
    } finally {
      dispatch({});
      spinner.stop();
    }
  };

  /**
   * 임시로 JWT 토큰을 생성해서 새 창으로 띄운다.
   */
  const openNewWindowWithToken = () => {
    const url = `http://cms.sekc.or.kr`;
    const newWindow = window.open(url, '_blank');
  };

  return (
    <div id="headerin">
      <div id="top-bar" className="clr container">
        {/* <!--s:top-bar-content--> */}
        <div id="top-bar-content" className="wpex-clr has-content top-bar-right">
          <div style={{ float: 'left' }}>
            <div className="top-bar-list">
              <ul>
                {
                  /**
                   * 서브메뉴(SubSideMenu)에서 카테고리 리스트를 가져오기 위해서 DB(mysql)에 그룹과 카테고리를 넣었다.
                   * 그래서 코드가 조금 난잡하다.. 이게 잘하는 짓인가?
                   * 2022.05.23.(월) @함양교회
                   * */
                  isLogin() ? ( // 로그인 했으면..
                    <>
                      <li>
                        <Link to="/" onClick={_handleLogout}>
                          로그아웃
                        </Link>
                      </li>
                      <li className={pathname.split('/')[4] === 'edit' ? 'active' : ''}>
                        <Link to={`/member/authorized/${categories?.find((c) => c.categoryCode === 'edit')?.id}/edit`}>정보관리</Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className={pathname.split('/')[4] === 'login' ? 'active' : ''}>
                        <Link to={`/member/unauthorized/${categories?.find((c) => c.categoryCode === 'login')?.id}/login`}>로그인</Link>
                      </li>
                      <li className={pathname.split('/')[4] === 'signup' ? 'active' : ''}>
                        <Link to={`/member/unauthorized/${categories?.find((c) => c.categoryCode === 'signup')?.id}/signup`}>회원가입</Link>
                      </li>
                    </>
                  )
                }
                {
                  /* 관리자 레벨(level: 30) 이상만 [회원 리스트]와 [메인롤링 슬라이드]를 수정할 수 있다. */
                  isLogin() && user.level >= DataTypes.USER_LEVEL_MANAGER ? (
                    <>
                      <li className={groupCode === 'manager' ? 'active' : ''}>
                        <Link to={`/admin/manager/${settings.find((set) => set.name === 'category_member_list_id')?.value}/memberList`}>관리자</Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )
                }
                {isLogin() && user.level >= DataTypes.USER_LEVEL_PASTOR && (
                  <li className={groupCode === 'ycms' ? 'active' : ''}>
                    <a href="#">
                      <span onClick={openNewWindowWithToken}>⑆YCMS</span>
                    </a>
                  </li>
                )}
                <li>
                  <a href="https://acmsnet.org" target="_blank" rel="noreferrer">
                    [ACMS]
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!--//e:top-bar-content--> */}
      </div>
    </div>
  );
};

export default TopBar;

import React from 'react';
import styles from './TabMenu.module.css';

const TabMenu = ({ activeTab, tabs, onTabChange }) => {
  return (
    <div className={styles.tabMenu}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`${styles.tabButton} ${activeTab === tab.id ? styles.activeTab : styles.inActiveTab}`} //
          onClick={() => onTabChange(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default TabMenu;
